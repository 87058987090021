(function () {
    angular.module('informaApp')
        .service('NewFeatureFileTypes', NewFeatureFileTypes);

    function NewFeatureFileTypes() {
        return {
            video: 1,
            image: 2
        };
    }
})();
